require("core-js/modules/es.array.filter");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

require("core-js/modules/es.string.split");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/popup/style", "vant/es/popup", "vant/es/field/style", "vant/es/field", "vant/es/button/style", "vant/es/button"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/popup/style"), require("vant/es/popup"), require("vant/es/field/style"), require("vant/es/field"), require("vant/es/button/style"), require("vant/es/button"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.popup, global.style, global.field, global.style, global.button);
    global.checkDevice = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _popup, _style2, _field, _style3, _button) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _popup = _interopRequireDefault(_popup);
  _field = _interopRequireDefault(_field);
  _button = _interopRequireDefault(_button);
  // import { getYMD } from '@/utils/time'
  var _default = {
    name: "CheckDevice",
    components: {
      Button: _button.default,
      Field: _field.default,
      Popup: _popup.default
    },
    data: function data() {
      var ossHost = this.$config.base.ossHost;
      return {
        ossHost: ossHost,
        loading: false,
        checkList: {},
        code: '',
        showModal: false,
        isEnable: false,
        item: {},
        inputFocus: false
      };
    },
    created: function created() {
      var vm = this; // const { USER_NAME } = vm.$config.keys;
      // let name = vm.$localStorage.getItem(USER_NAME);

      var uuid = vm.$localStorage.getItem('uuid') || vm.$route.query.uuid;
      var fType = vm.$strTool.filterFactoryType(uuid);
      var checkDeviceCache = vm.$localStorage.getItem('checkDeviceCache') || '';

      if (fType != 'lansong') {
        vm.$router.replace({
          path: "404"
        });
        return;
      }

      if (checkDeviceCache) {
        vm.checkList = checkDeviceCache;
      } else {
        vm.loadDeviceJSON();
      }

      vm.weixinInit();
    },
    computed: {
      isPass: function isPass() {
        return this.code.length == 11;
      }
    },
    methods: {
      inputChange: function inputChange(e) {
        console.log(e); // Toast(JSON.stringify(e))
      },
      loadDeviceJSON: function loadDeviceJSON() {
        var vm = this;
        vm.loading = true;
        vm.$http({
          type: "get",
          url: "".concat(vm.ossHost, "file/probe30.json"),
          except: true
        }).then(function (res) {
          vm.$localStorage.setItem('checkDeviceCache', res);
          vm.checkDeviceCache = JSON.parse(res);
        });
      },
      weixinInit: function weixinInit() {
        var vm = this;
        vm.$http({
          type: "get",
          url: "https://m.yudada.com/jssdk/configuration?url=".concat(location.href)
        }).then(function (res) {
          var _res$data = res.data,
              appId = _res$data.appId,
              nonceStr = _res$data.nonceStr,
              signature = _res$data.signature,
              timestamp = _res$data.timestamp;
          window.wx.config({
            debug: false,
            appId: appId,
            timestamp: timestamp,
            nonceStr: nonceStr,
            signature: signature,
            jsApiList: ["scanQRCode"]
          });
        });
      },
      scan: function scan() {
        var vm = this;
        window.wx.scanQRCode({
          needResult: 1,
          scanType: ["barCode"],
          success: function success(res) {
            var result = res.resultStr.replace('Z', '').split(',')[1];
            vm.code = result;
          }
        });
      },
      checkDevcie: function checkDevcie() {
        var vm = this;
        var item = vm.checkList.list.filter(function (li) {
          return li.code == "Z".concat(vm.code);
        })[0] || {};

        if (item && item.code) {
          vm.item = item;
          vm.isEnable = false;
        } else {
          vm.item = {};
          vm.isEnable = true;
        }

        vm.showModal = true;
      }
    }
  };
  _exports.default = _default;
});